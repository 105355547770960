@import "../../assets/styles/global";
@import "../../utils/style.scss";

.homeContainer {
  margin-top: 70px;
  .coverContainer {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media(desktop-md) {
        height: 150px;
      }
    }
    .content {
      text-align: center;
      line-height: 4.7rem;
      position: absolute;
      top: 25vh;
      left: 50%;
      transform: translate(-50%, -50%);
      width: fit-content;
      color: $white;
      font-weight: bold;
      font-size: 48px;
      text-shadow: 0px 2px 6px rgba($black, 0.5);
      @include media(desktop-md) {
        font-size: 18px;
        top: 10vh;
        line-height: 2.7rem;
      }
      h3 {
        font-size: 36px;
        @include media(desktop-md) {
          font-size: 18px !important;
        }
      }
    }
  }
  section {
    padding: 20px 35px;
    @include media(desktop-md) {
      padding: 20px 15px;
    }
    &:nth-of-type(even) {
      background-color: rgba($color: $primary, $alpha: 0.05);
    }
    h2 {
      color: $primary;
      font-size: 48px;
      @include media(desktop-md) {
        font-size: 24px;
      }
    }
    hr {
      width: 100px;
      border-width: 3px;
      border-style: solid;
      border-radius: 30px;
      border-color: $primary;
      @include media(desktop-md) {
        width: 50px;
      }
    }
    p {
      margin-top: 20px;
      line-height: 2.25rem;
      letter-spacing: 0.15rem;
      @include media(desktop-md) {
        line-height: 2rem;
      }
      span {
        color: $primary;
      }
    }
    .grid-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
      margin: 10px 5px;
      margin-top: 100px;
      @include media(desktop-md) {
        grid-template-columns: repeat(1, 1fr);
      }
      .grid-item {
        // grid-column: span 1;
        align-self: flex-start;
        @include media(desktop-md) {
          align-self: center;
        }
        margin-bottom: 5px; /* Adjust the margin between grid items as needed */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        .title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          h3 {
            color: $primary;
          }
        }
        .content {
          line-height: 2.25rem;
          letter-spacing: 0.15rem;
          @include media(desktop-md) {
            text-align: center;
          }
          span {
            color: $primary;
            font-weight: bold;
          }
        }
        .container {
          transition: box-shadow 0.3s ease-in-out;
          box-shadow: 0px 4px 12px rgba($black, 0.5);
          border-radius: 8px;
          padding: 20px 30px;
          text-align: center;
          min-height: fit-content;
          height: 400px;
          width: 450px;
          @include media(desktop-md) {
            width: fit-content;
            height: fit-content;
          }
          cursor: pointer;
          .content {
            margin-top: 30px;
          }
        }
      }
    }
  }
  .servicesS {
    .grid-item {
      transition: transform 0.3s ease-in-out;
      &:hover {
        @include media(desktop-md) {
          transform: scale(1);
        }
        transform: scale(1.1); /* Enlarge the item on hover */
      }
    }
  }
  .policiesS {
    .subSection {
      margin-top: 20px;
      .data {
        margin-left: 30px;
        ul {
          list-style-type: none;
          padding: 0;
        }

        li {
          position: relative;
          padding-left: 20px;
          line-height: 1.5;
          &::before {
            content: "\2022";
            color: $primary;
            font-size: 1.5em;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
