@import "../../assets/styles/global";

.contactUsContainer {
  margin-top: 70px;

  h2,
  hr {
    margin-inline: 35px;
  }
  &:nth-of-type(even) {
    background-color: rgba($color: $primary, $alpha: 0.05);
  }
  h2 {
    color: $primary;
    font-size: 48px;
    @include media(desktop-md) {
      font-size: 28px;
    }
  }
  hr {
    width: 100px;
    border-width: 3px;
    border-style: solid;
    border-radius: 30px;
    border-color: $primary;
    @include media(desktop-md) {
      width: 50px;
    }
  }
  p {
    margin-top: 20px;
    line-height: 2.25rem;
    letter-spacing: 0.15rem;
    @include media(desktop-md) {
      line-height: 2rem;
    }
    span {
      color: $primary;
    }
  }
}
