@import "../../assets/styles/global";

.contact {
  margin-top: 100px;
  width: 100vw;
  background-color: rgba($color: $primary, $alpha: 0.1);
  .container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 40px 35px;
    min-height: 500px;
    @include media(desktop-md) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .details {
      & > div {
        margin-block-end: 20px;
        .icon {
          display: flex;
          gap: 10px;
          align-items: center;
          svg {
            vertical-align: middle;
          }
          font-weight: bold;
          letter-spacing: 0.09rem;
          font-size: 24px;
        }
        .data {
          margin-inline-start: 35px;
          font-size: 18px;
          font-weight: 500;
          font-style: italic;
          cursor: pointer;
          letter-spacing: 0.09rem;
        }
      }
    }
    .form {
      position: relative;
      .container {
        background-color: $white;
        border-radius: 8px;
        padding: 40px 50px;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 600px;
        height: 600px;
        box-shadow: 0px 2px 12px rgba($color: $primary, $alpha: 0.5);
        top: 50%;
        transform: translate(-70%, -50%);
        @include media(desktop-md) {
          position: unset;
          justify-content: flex-start;
          align-items: flex-start;
          transform: translate(0, 0);
          width: 85vw;
          height: fit-content;
          h1 {
            font-size: 18px !important;
          }
        }
        h1 {
          font-size: 28px;
          font-weight: bold;
          letter-spacing: 0.1rem;
          span {
            color: $primary;
          }
          @include media(desktop-md) {
            font-size: 18px;
          }
        }
        hr {
          width: 100px;
          border-width: 3px;
          border-style: solid;
          border-radius: 30px;
          border-color: $primary;
          margin-block-end: 20px;
          @include media(desktop-md) {
            width: 50px;
          }
        }
        form {
          margin-block-start: 20px;
          display: flex;
          flex-direction: column;
          width: 100%;

          input,
          textarea {
            padding: 5px 20px;
            margin-block-end: 20px;
            border: none;
            border-bottom: 1px solid $lightGray;
            @supports (-webkit-appearance: none) {
              &:focus {
                box-shadow: 0 0 0 2px rgba($color: $primary, $alpha: 0.5);
                outline: none;
              }
            }
          }

          button {
            width: 250px;
            border: none;
            outline: none;
            background-color: rgba($color: $primary, $alpha: 0.9);
            color: $white;
            font-weight: bold;
            font-size: 16px;
            text-align: center;
            cursor: pointer;
            border-radius: 8px;
            box-shadow: 0px 2px 12px rgba($color: $black, $alpha: 0.3);
            padding: 5px 20px;
            @include media(desktop-md) {
              width: 100px;
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }
}
