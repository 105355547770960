@import "../../assets/styles/global";

.footerContainer {
  .footer {
    margin-block-start: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .logoContainer {
      height: 100px;
      width: 100px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .links {
      @include media(desktop-md) {
        display: none;
      }
      display: flex;
      gap: 20px;
      a {
        text-decoration: none;
        color: $black;
        &:hover {
          color: $primary;
        }
        color: $black;
      }
      .active {
        color: $primary;
      }
    }
    .social {
      display: flex;
      gap: 20px;
      @include media(desktop-md) {
        display: none;
      }
    }
  }
}
