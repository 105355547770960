//Colors
$primary: #dd5b00;
$black: #000000;
$white: #ffffff;
$gray: #4b4b4b;
$lightGray: #eaeaea;
$purple: #5352ed;
$dark-grey: #0000004d;
$light-grey: #00000029;
$mid-grey: #a5b1c2;
$dark-green: #2e5361;
$grey: #a7a6a6;
$pink: #f19895;
$light-orange: #f8ba7d;
$yellow: #ffdc64;
$red: #e74c3c;
$light-green: #2ecc71;

//Fonts
@font-face {
  src: url("../fonts/saved-by-zero-rg.ttf");
  font-family: Zero;
}
@font-face {
  src: url("../fonts/Cairo-Regular.ttf");
  font-family: Cairo;
  font-weight: 400;
}
@font-face {
  src: url("../fonts/Cairo-SemiBold.ttf");
  font-family: Cairo;
  font-weight: 500;
}
@font-face {
  src: url("../fonts/Cairo-Bold.ttf");
  font-family: Cairo;
  font-weight: 600;
}

//Fonts
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@500;600;700&display=swap"); //font-family: 'Libre Franklin', sans-serif;
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;700&display=swap"); //Tajawal => font-family: 'Tajawal', sans-serif;
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;600&display=swap"); //Cairo => font-family: 'Cairo', sans-serif;

/********************************************************/

//Media Queries
@mixin media($breakpoint) {
  @if $breakpoint == phone-sm {
    @media only screen and (max-width: 320px) {
      @content;
    }
  }
  @if $breakpoint == phone-md {
    @media only screen and (max-width: 480px) {
      @content;
    }
  }
  @if $breakpoint == phone-lg {
    @media only screen and (max-width: 576px) {
      @content;
    }
  }
  @if $breakpoint == tablet-sm {
    @media only screen and (max-width: 798px) {
      @content;
    }
  }
  @if $breakpoint == tablet-md {
    @media only screen and (max-width: 900px) {
      @content;
    }
  }
  @if $breakpoint == tablet-lg {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  }
  @if $breakpoint == desktop-sm {
    @media only screen and (max-width: 1200px) {
      @content;
    }
  }
  @if $breakpoint == desktop-md {
    @media only screen and (max-width: 1366px) {
      @content;
    }
  }
  @if $breakpoint == desktop-lg {
    @media only screen and (max-width: 1680px) {
      @content;
    }
  }
  @if $breakpoint == small-height {
    @media only screen and (max-height: 800px) {
      @content;
    }
  }
}

// Global Styles
html {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-family: Cairo, sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: $black;
  background-color: $white;
  overflow: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0px;
  }
  direction: ltr;
  // -webkit-overflow-scrolling: touch;
  text-align: left;
}
* {
  margin: 0;
  padding: 0;
  font-family: Cairo, sans-serif;
  box-sizing: border-box;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

//scrollbar style
*::-webkit-scrollbar {
  width: 0px;
}
*::-webkit-scrollbar-track {
  background-color: $white;
}
*::-webkit-scrollbar-thumb {
  background-color: $black;
  border-radius: 5px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.notification-container {
  top: unset !important;
  bottom: 30px !important;
}

@include media(phone-lg) {
  h1 {
    font-size: 40px !important;
  }
  h3 {
    font-size: 20px !important;
  }
  h5 {
    font-weight: normal !important;
  }
  p {
    font-size: 16px !important;
  }
  button {
    font-size: 20px !important;
  }
}

#root,
.app-container {
  display: flex;
  flex-direction: column;
  background-color: $white;
  color: $black;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  font-family: "Cairo";
}

//Animations
@keyframes roll {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin box-animate {
  animation: box 0.3s ease 1;
}
@keyframes box {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(unset);
  }
}
