@import "../../assets/styles/global";

.navbarContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 70px;
  box-shadow: 0px 2px 4px rgba($black, 0.5);
  display: flex;
  align-items: center;
  padding: 10px 20px;
  .container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .logoContainer {
      width: 50px;
      height: 50px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .burgerMenu {
      display: none; // Hide the burger menu icon by default for larger screens
      cursor: pointer;

      .bar {
        height: 3px;
        width: 25px;
        background-color: $black;
        margin: 6px 0;
      }
    }

    .links {
      display: flex;
      align-items: center;
      gap: 40px;

      a {
        text-decoration: none;
        color: $black;

        &:hover {
          color: $primary;
        }

        &.active {
          color: $primary;
        }
      }
    }
  }

  // Media query for smaller screens
  @include media(desktop-md) {
    .container {
      justify-content: space-between;
      .burgerMenu {
        display: block; // Show the burger menu icon for smaller screens
      }

      .links {
        background-color: white;
        z-index: 100;
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        top: 70px;
        left: 0;
        background-color: #fff; // Adjust background color as needed
        width: 100%;
        padding: 10px;
        display: none; // Hide the mobile links by default
        padding: 10px 20px;
        box-shadow: 0px 2px 12px rgba($black, 0.5);

        &.mobileLinksOpen {
          display: flex; // Show the mobile links when the mobile menu is open
        }

        .nav {
          margin-right: 0;

          a {
            color: $black;
            font-size: 18px;
            margin-bottom: 10px;

            &:hover {
              color: $primary;
            }

            &.active {
              color: $primary;
            }
          }
        }
      }
    }
  }
}
